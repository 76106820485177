<template>
  <!-- BEGIN: Top Bar -->
  <div
    class="
      top-bar-boxed
      -mt-7
      md:-mt-5
      -mx-3
      sm:-mx-8
      px-3
      sm:px-8
      md:pt-10
      mb-12
    "
  >
    <div class="h-full flex items-center">
      <!-- BEGIN: Logo -->
      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <div
        class="-intro-x breadcrumb mt-10 lg:mt-0"
        :class="{
          'ml-auto': isRTL,
          'mr-auto': !isRTL
        }"
      >
        <a class="cursor-pointer" @click="this.$router.push('/')">{{
          i18n('breadcrumbs.application')
        }}</a>
        <ChevronRightIcon class="breadcrumb__icon" v-if="!isRTL" />
        <ChevronLeftIcon class="breadcrumb__icon" v-else />
        <a class="breadcrumb--active">{{ i18n(`menu.${pageTitle}`) }}</a>
      </div>
      <!-- END: Breadcrumb -->
      <div class="mt-10 lg:mt-0">
        <i18nFlags class="i18nFlags" />
      </div>
      <!-- BEGIN: Account Menu -->
      <div class="dropdown">
        <button class="dropdown-toggle" aria-expanded="false">
          <div
            class="
              dropdown-toggle
              w-8
              h-8
              rounded-full
              overflow-hidden
              shadow-lg
              image-fit
              zoom-in
              scale-110
              mr-4
              ml-4
              mt-10
              lg:mt-0
            "
            role="button"
            aria-expanded="false"
            @click="showAccountDropDown = true"
          >
            <img
              :src="currentUserAvatar"
              style="border-radius: 50%; height: 100%"
              v-if="currentUserAvatar"
            />
            <img
              :src="require(`@/assets/images/${$f()[9].photos[0]}`)"
              alt="Icewall Tailwind HTML Admin Template"
              v-else
            />
          </div>
        </button>
        <div class="dropdown-menu" v-if="showAccountDropDown">
          <div
            class="dropdown-menu__content profileDropDown box dark:bg-dark-6"
          >
            <div class="p-4">
              <div class="font-medium">{{ currentUserNameOrEmailPrefix }}</div>
            </div>
            <div class="p-2">
              <div
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  hover:bg-theme-31 hover:text-white
                  rounded-md
                  cursor-pointer
                "
                @click="goToProfile()"
              >
                <UserIcon class="w-4 h-4" :class="isRTL ? 'ml-2' : 'mr-2'" />
                {{ i18n('topbar.profile.profile') }}
              </div>
            </div>
            <div class="p-2">
              <div
                href=""
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  hover:bg-theme-31 hover:text-white
                  rounded-md
                  cursor-pointer
                  dropdown-toggle
                "
                @click="doLogout()"
              >
                <ToggleRightIcon
                  class="w-4 h-4"
                  :class="isRTL ? 'ml-2' : 'mr-2'"
                />
                {{ i18n('topbar.profile.logout') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Account Menu -->
    </div>
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import VueLottiePlayer from 'vue-lottie-player'

export default defineComponent({
  computed: {
    ...mapGetters({
      currentUserNameOrEmailPrefix: 'auth/currentUserNameOrEmailPrefix',
      currentUserAvatar: 'auth/currentUserAvatar',
      currentUser: 'auth/currentUser',

      // newNotifications: 'notification/list/newNotifications',
      // unreadNotifications: 'notification/list/unreadNotificationsToHeader',
      // latestNotifications: 'notification/list/latestNotifications',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop'
      // currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    isMobile() {
      return screen.width <= 425
    },
    pageTitle() {
      var currentPage = this.$route.name
      if (currentPage.includes('menu')) return 'Menu'
      if (currentPage.includes('profile')) return 'Profile'
      return ''
    }
  },
  setup() {
    const searchDropdown = ref(false)
    const showAccountDropDown = ref(false)
    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    return {
      showAccountDropDown,
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown
    }
  },
  methods: {
    ...mapActions({
      toggleMenu: 'layout/toggleMenu',
      doSignout: 'auth/doSignout'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    goToProfile() {
      this.showAccountDropDown = false
      this.$router.push('/profile')
    },
    doLogout() {
      this.showAccountDropDown = false
      this.doSignout()
      // this.$router.push('/auth/login')
    }
  }
})
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');
.i18nFlags {
  @media only screen and (max-width: 425px) {
    display: none;
  }
}
.searchDropdown {
  width: 200px !important;
  position: absolute !important;
  right: 0 !important;
}
[dir='rtl'] .searchDropdown {
  right: unset !important;
  left: 0 !important;
}

.notificationDropDown {
  width: 300px !important;
  position: absolute !important;
  right: 0 !important;
}
[dir='rtl'] .notificationDropDown {
  right: unset !important;
  left: 0 !important;
}

.profileDropDown {
  width: 200px !important;
  position: absolute !important;
  right: 0 !important;
}
[dir='rtl'] .profileDropDown {
  right: unset !important;
  left: -20px !important;
}
</style>
